import React from 'react';
import AmazonAdComponent from './ads/AmazonAdComponent';

export default function IronCondorSpread() {
    return (
        <>
 			<div id="left">
				<h1>
					Option Strategies
					<br />
				</h1>
				<h4>Iron Condor
				</h4>
				An Iron Condor Option is the ultimate options combining strategy.  It is
				basically two vertical option spreads combined into one strategy.  You
				use this technique in a couple of scenarios.  One is when you are dealing with
				a cyclical stock and you are relatively sure the price will be in a certain range for
				the time period in question.
				<br /><br />
				Before going on, I would suggest you read through <strong>Vertical Spread</strong> section again and then come back to this section.
                <br /><br />
                Some important things to note about Iron Condors:
                <ol>
                  <li>It is typically a credit spread; that is you receive money for taking the position.
                  </li>
                  <li>Two credit vertical spreads makeup the entire Iron Condor spread.  One Bear Call and one Bull Put.
                  </li>
                  <li>The two verticals must be for the same expiration month.
                  </li>
                  <li>The number of contracts for both verticals must be the same.  I.e., if the Call Spread is for 10 contracts each,
                      then the Put Spread must be 10 contracts each.
                  </li>
                </ol>
                Right off the bat, you will notice that you will be trading a lot of contracts.  If your broker is not option
                friendly, then many times assuming an Iron Condor position will not make sense since your credit will be eaten
                by the commissions.
                <br /><br />
				<AmazonAdComponent type='mid' />
                Lets take an example: <br />
				VZ is currently trading at $28.
				<div class="block">
					<table width="100%" cellspacing="0" cellpadding="0" border="1" class="example">
						<thead>
							<tr>
								<td>
									Option Type
									<br />
								</td>
								<td>
									Action
									<br />
								</td>
								<td>
									Contracts
									<br />
								</td>
								<td>
									Symbol
									<br />
								</td>
								<td>
									Strike
									<br />
								</td>
								<td>
									Exp Month
									<br />
								</td>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>Call
								</td>
								<td>Sell
								</td>
								<td>-12
								</td>
								<td>VZ
								</td>
								<td>30
								</td>
								<td>AUG
								</td>
							</tr>
							<tr>
								<td>Call
								</td>
								<td>Buy
								</td>
								<td>12
								</td>
								<td>VZ
								</td>
								<td>31
								</td>
								<td>AUG
								</td>
							</tr>
							<tr>
								<td>Put
								</td>
								<td>Sell
								</td>
								<td>-12
								</td>
								<td>VZ
								</td>
								<td>25
								</td>
								<td>AUG
								</td>
							</tr>
							<tr>
								<td>Put
								</td>
								<td>Buy
								</td>
								<td>12
								</td>
								<td>VZ
								</td>
								<td>24
								</td>
								<td>AUG
								</td>
							</tr>
						</tbody>
					</table>
				</div>
				<br /><br />
				Just as a recap, remember that the objective of the Bear Call Spread is to watch the stock fall below the short strike option
				(i.e., VZ $30 AUG).  If that happens you keep the credit. If not, then the most you lose is the spread itself. In this case,
				$100 per contract.   In the case of Bull Put Spread, your objective is to watch the stock rise above the short strike option
				(i.e., VZ $25 AUG).  The consequences are the same as in the Bear Call Spread.
				<br /><br />
				You will notice right away that VZ is trading at $28 and there is a $5 buffer. I.e., the difference between
				the $30 Strike Price Call and $25 Strike Price Put.  Although this stock can move, it traditionally doesn't
				move so much in any given month.  So as long as Verizon doesn't go above $30 and below $25, you get to keep your
				credit, which in this case was $25.  And if Verizon did make a move in one direction, then the max lose would be the
				same as in a regular vertical spread.  The other side of the Iron Condor credit would help buffer the loss.
				<br /><br />
				You should be asking yourself about now why should I trade 40 contracts just to bring in $25?  That is where your
				option friendly broker will come into play.  The commission rate should be very very cheap for you to consider
				this trade.  But if you do and pick the right stocks, this is a very safe strategy and you can execute multiple Iron Condors
				to supplement your monthly cash flow.  Also, keep in mind that $25 is kind of low, even for Iron Condors.
				<br /><br />
				There are other flavours of the Iron Condor soon to come...so please check back again at a later date.  And always,
				remember to click on those links to help support our effort to educate investors!
			</div>
        </>
    )
}