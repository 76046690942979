import React from 'react';
import GoogleAdComponent from './ads/GoogleAdComponent';
import AmazonAdComponent from './ads/AmazonAdComponent';

export default function Home() {
    return (
        <>
			<div id="left">
				<h1>
					Welcome to Options for Dummies
					<br />
				</h1>
					Have you ever been curious about options? Heard people talk about
					how much money they made playing with options? You want to get in
					on the action and become an options trader yourself but not sure
					how to get started?  I'm sure there are plenty of options tutorial
					web sites out there which explain options to you
					but you may not understand it because they don't give enough
					examples or explain it in clear plain english.
					<br /><br />
					<AmazonAdComponent type='mid' />
					Here you will learn the basics of options, mini options, how to make money
					trading options, and different option strategies; all in plain
					english anyone can understand.  This web site is meant to be the ultimate
					Options Tutorial for the beginner.  Not Trading Options 101, but Trading Options
					001.
					<br /><br />
					You next stop is reading the Options Basics article to learn about
					What is an Option, how to trade options, and option characteristics.  
					<br /><br />
					You can also refer to these web sites below while you are reading some of the
					material from this web site. <br />
					<br />
				<h4>
					Other Helpful Websites
				</h4>
				{/* <p> */}
				Here are some websites you can visit to lookup specific terms and/or
				do research on a stock.
                <br />
                <form action="http://www.optionsfordummies.net/index.html" id="cse-search-box">
                <div>
                    <input type="hidden" name="cx" value="partner-pub-4682642215710738:oak0mw-wrpu" />
                    <input type="hidden" name="cof" value="FORID:10" />
                    <input type="hidden" name="ie" value="ISO-8859-1" />
                    <input type="text" name="q" size="20" />
                    <input type="submit" name="sa" value="Search" />
                </div>
                </form>
                <script type="text/javascript" src="http://www.google.com/cse/brand?form=cse-search-box&amp;lang=en"></script>
                <div id="cse-search-results"></div>
                <script type="text/javascript">
                var googleSearchIframeName = "cse-search-results";
                var googleSearchFormName = "cse-search-box";
                var googleSearchFrameWidth = 800;
                var googleSearchDomain = "www.google.com";
                var googleSearchPath = "/cse";
                </script>
                <script type="text/javascript" src="http://www.google.com/afsonline/show_afs_search.js"></script>
                                <ul>
                                    <li><a target="_new" href="http://www.investopedia.com">Investopedia</a></li>
                                    <li><a target="_new" href="http://finance.yahoo.com">Yahoo</a></li>
                                    <li><a target="_new" href="http://finance.google.com">Google Finance</a></li>
                                    <li><a target="_new" href="https://www.spdrs.com/product/">Spider ETFs</a></li>
                                </ul>
                                <br /><br />
                <script async src="//pagead2.googlesyndication.com/pagead/js/adsbygoogle.js"></script>
                <GoogleAdComponent googleAdSlotID='9563874105' />
				{/* </p> */}
				</div>
        </>
    )
}