import React from 'react';
import ListItem from '@material-ui/core/ListItem';
import ListItemIcon from '@material-ui/core/ListItemIcon';
import ListItemText from '@material-ui/core/ListItemText';
import ListSubheader from '@material-ui/core/ListSubheader';
import DashboardIcon from '@material-ui/icons/Dashboard';
import AssignmentIcon from '@material-ui/icons/Assignment';
import ShowChartIcon from '@material-ui/icons/ShowChart';
import { PATHS } from '../Paths';

export const mainListItems = (callback) => {
  return (
  <div>
    <ListItem button onClick={()=>callback(PATHS.HOME)}>
      <ListItemIcon>
        <DashboardIcon />
      </ListItemIcon>
      <ListItemText primary="Home" />
    </ListItem>
    <ListItem button>
      <ListItemIcon>
        <ShowChartIcon />
      </ListItemIcon>
      <ListItemText primary="Basics"  onClick={()=>callback(PATHS.BASICS)} />
    </ListItem>
    <ListItem button>
      <ListItemIcon>
        <ShowChartIcon />
      </ListItemIcon>
      <ListItemText primary="Strategies"  onClick={()=>callback(PATHS.STRATEGIES)} />
    </ListItem>
  </div>
)};

export const secondaryBasicsListItems = (callback) => (
  <div>
    <ListSubheader inset>Basics Submenu</ListSubheader>
    <ListItem button onClick={()=>callback(PATHS.WEEKLYOPTIONS)}>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Weekly Options" />
    </ListItem>
    <ListItem button onClick={()=>callback(PATHS.MINIOPTIONS)}>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Mini Options" />
    </ListItem>
    <ListItem button  onClick={()=>callback(PATHS.CHEATSHEET)}>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Options Cheatsheet" />
    </ListItem>
    <ListItem button  onClick={()=>callback(PATHS.BASICEXAMPLES)}>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Basic Examples" />
    </ListItem>
  </div>
);


export const secondaryStrategiesListItems = (callback) => (
  <div>
    <ListSubheader inset>Strategies Submenu</ListSubheader>
    <ListItem button  onClick={()=>callback(PATHS.COVEREDCALL)}>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Covered Call" />
    </ListItem>
    <ListItem button onClick={()=>callback(PATHS.PROTECTIVEPUT)}>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Protective Put" />
    </ListItem>
    <ListItem button onClick={()=>callback(PATHS.SECUREDPUT)}>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Secured Put" />
    </ListItem>
    <ListItem button onClick={()=>callback(PATHS.VERTICALSPREAD)}>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Vertical Spread" />
    </ListItem>
    <ListItem button onClick={()=>callback(PATHS.CALENDARSPREAD)}>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Calendar Spread" />
    </ListItem>
    <ListItem button onClick={()=>callback(PATHS.DIAGONALSPREAD)}>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Diagonal Spread" />
    </ListItem>
    <ListItem button onClick={()=>callback(PATHS.STRANGLE)}>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Strangle" />
    </ListItem>
    {/* <ListItem button onClick={()=>callback(PATHS.STRADDLE)}>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Straddle" />
    </ListItem> */}
    <ListItem button  onClick={()=>callback(PATHS.IRONCONDORSPREAD)}>
      <ListItemIcon>
        <AssignmentIcon />
      </ListItemIcon>
      <ListItemText primary="Iron Condor Spread" />
    </ListItem>
  </div>
);
