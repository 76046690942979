import React from 'react';
import AmazonAdComponent from './ads/AmazonAdComponent';

export default function MiniOptions() {
    return (
        <>
 			<div id="left">
				<h1>
					Mini Options
					<br />
				</h1>
				<h4>
					What is a Mini Option?
					<br />
				</h4>
					So you've heard about Options by now, but what about a <strong>Mini Option</strong>?
					Well as it turns out you can buy, sell, and trade a Mini Option just like a regular option.  The only difference
					is that instead of representing 100 shares in the underlying stock, a Mini Option represents just 10 shares in the 
					underlying stock.  
					<br />
					<br />
					Now why would someone need a Mini Option?  Well imagine a stock like Apple which was trading not too long ago at $620 per share! 
					You can imagine that to buy a single option of Apple would cost around $700 per option depending on factors such as time to expiration or
					how close to the money the underlying stock is currently trading. 
					<br />
					<br />
					Well all things being equal, if that Apple option were a Mini Option, it would cost you about a tenth of the cost, or around $70 per option!
					Well that's a great deal, but remember that a Mini Option only controls 10 shares instead of the standard 100 shares of a regular option.  But
					if what you're looking for is pure price action and to partake in the percentage increase in value, a Mini Option could be right for you. 
					<br />
					<br />
					The catch is you cannot buy Mini Options for all stocks.  As of March 18, 2013 only five securities have been authorized to trade Mini Options.  Those are described below:
					You can be sure that as demand for Mini Options increases, more will be available.  You can keep checking back here to find out the status!
					<br />
					<AmazonAdComponent type='mid' />
					
				<div class="block">
					<table width="100%" cellspacing="0" cellpadding="0" border="1" class="example">
						<thead>
							<tr>
								<td>
									Symbol
									<br />
								</td>
								<td>
									Name
									<br />
								</td>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>
									SPY
									<br />
								</td>
								<td>
									{"SPDR S&P 500.  (SPDR is prounouced 'Spider')."}
									<br />
								</td>
							</tr>
							<tr>
								<td>
									AAPL
									<br />
								</td>
								<td>
									Apple
									<br />
								</td>
							</tr>
							<tr>
								<td>
									GLD
									<br />
								</td>
								<td>
									SPDR Gold Trust
									<br />
								</td>
							</tr>
							<tr>
								<td>
									GOOG
									<br />
								</td>
								<td>
									Google, Inc.
									<br />
								</td>
							</tr>
							<tr>
								<td>
									AMZN
									<br />
								</td>
								<td>
									Amazon.com, Inc.
									<br />
								</td>
							</tr>
						</tbody>
					</table>
				</div>
			</div>
        </>
    )
}