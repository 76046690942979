import React from 'react';
import AmazonAdComponent from './ads/AmazonAdComponent';

export default function SecuredPut() {
    return (
        <>
 			<div id="left">
				<h1>
					Option Strategies
					<br />
				</h1>
				<h4>Secured Put
				</h4>
				In this scenario you don't own any shares, but you wouldn't mind owning Bank of America
				(BAC) shares if it went down a little in price.  You're fairly sure that BAC has good
				long term potential so you want to take advantage while its down.  Currently it is
				trading around $9.07/share.  You think that if it drops any lower you want to buy.  So
				you submit a "Sell to open" order for 10 Put options at the $9 strike price.
				Now two things will happen.
				<AmazonAdComponent type='mid' />
				<ol>
					<li>
						Each option costs $1.18.  So you will receive in cash the total premium.  I.e., $1,180.
					</li>
					<li>
						Your brokerage firm will ensure that you have at least $9,000 in cash or cash equivalents.
						Why? Because by selling Put options you've given someone the right to sell you 1000 shares
						of BAC at $9/share.  Your broker wants to make sure you can live up to your
						obligation.  That's where the name "Secured" comes into play.
					</li>
				</ol>

				<br /><br />
			</div>
        </>
    )
}