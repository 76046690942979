import React, { useEffect } from 'react';
import './App.css';
import Dashboard from './dashboard/Dashboard';

function App() {
  useEffect(() => {
    var loadScript = function (src) {
        var tag = document.createElement('script');
        tag.async = false;
        tag.src = src;
        var body = document.getElementsByTagName('body')[0];
        body.appendChild(tag);
    }
    loadScript("//z-na.amazon-adsystem.com/widgets/onejs?MarketPlace=US&adInstanceId=b50de289-9be5-45b3-a5ed-7fff98699fb0");
    loadScript("//z-na.amazon-adsystem.com/widgets/onejs?MarketPlace=US&adInstanceId=d6bf6685-7933-4d55-91df-61c3e7cdb3bd");
    loadScript("https://pagead2.googlesyndication.com/pagead/js/adsbygoogle.js");
  });  
  return (
    <Dashboard />
    // <Container maxWidth="xl">
    //   <Box my={5}>
    //     <Typography variant="h2" gutterBottom>
    //       Options for Dummies
    //     </Typography>
    //     <Typography variant="h6" gutterBottom>
    //       Learn how to trade options
    //     </Typography> */}
    //     <AdComponent />
    //   </Box>
    //   <Box my={5}>
    //     <Copyright />
    //   </Box>
    // </Container>    
  );
}

export default App;
