import React from 'react';
import AmazonAdComponent from './ads/AmazonAdComponent';

export default function DiagonalSpread() {
    return (
        <>
 			<div id="left">
				<h1>
					Option Strategies
					<br />
				</h1>
				<h4>Diagonal Spread
				</h4>
				Remember our discussion on <strong>Calendar Spreads</strong>? Well if you didn't read that, 
				please click <a href="optionstrategy_calendarspread.html">here</a> to read up on that because a <strong>Diagonal Spread</strong> is simply another
				form of a Calendar Spread.  
				<br /><br />
				Okay so now you've brushed up on Calendar Spreads, let us continue.  So remember that a Calendar Spread is basically
				a type of Vertical Spread but instead of varying on the price, the two Call Options (or Put Options) vary based on the
				expiration month.  Also remember that Calendar Spreads are typically a net debit transaction.  So now what if you thought
				a Calendar Spread was too expensive for you to take on?   Well there is a way for you to finance the cost of a Calendar Spread and it's called
				a Diagonal Spread.  Let's see the table below to make this easier to understand. 
				
				<div class="block">
					<table width="100%" cellspacing="0" cellpadding="0" border="1" class="example">
						<thead>
							<tr>
								<td colspan="4">
									Calendar Call Spread for one option of IBM.
								</td>
							</tr>
							<tr>
								<td>
									Leg
									<br />
								</td>
								<td>
									Action
									<br />
								</td>
								<td>
									Expiration
									<br />
								</td>
								<td>
									Strike Price
									<br />
								</td>
								<td>
									Premium
									<br />
								</td>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>
									1
									<br />
								</td>
								<td>
									Buy
									<br />
								</td>
								<td>
									 April 2012
									<br />
								</td>
								<td>
									 $200
									<br />
								</td>
								<td>
									 $4.50
									<br />
								</td>
							</tr>
							<tr>
								<td>
									2
									<br />
								</td>
								<td>
									Sell
									<br />
								</td>
								<td>
									 March 2012
									<br />
								</td>
								<td>
									 $200
									<br />
								</td>
								<td>
									 -$2.30
									<br />
								</td>
							</tr>
						</tbody>
					</table>
				</div>
				<br />
				The above table represents a typical Calendar Spread.  Notice that you still end up paying a net debit of 
				$2.2.  If you want to lower that cost you will need to sell a Vertical Call Spread.  Here is a table representing
				the Vertical Call Spread.
				<AmazonAdComponent type='mid' />
				<div class="block">
					<table width="100%" cellspacing="0" cellpadding="0" border="1" class="example">
						<thead>
							<tr>
								<td colspan="4">
									Calendar Call Spread for one option of IBM.
								</td>
							</tr>
							<tr>
								<td>
									Leg
									<br />
								</td>
								<td>
									Action
									<br />
								</td>
								<td>
									Expiration
									<br />
								</td>
								<td>
									Strike Price
									<br />
								</td>
								<td>
									Premium
									<br />
								</td>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>
									1
									<br />
								</td>
								<td>
									Sell
									<br />
								</td>
								<td>
									 March 2012
									<br />
								</td>
								<td>
									 $195
									<br />
								</td>
								<td>
									 $5.40
									<br />
								</td>
							</tr>
							<tr>
								<td>
									2
									<br />
								</td>
								<td>
									Buy
									<br />
								</td>
								<td>
									 March 2012
									<br />
								</td>
								<td>
									 $200
									<br />
								</td>
								<td>
									 $2.30
									<br />
								</td>
							</tr>
						</tbody>
					</table>
				</div>
				<br />
				
				The first thing to point out is that the second leg of the Vertical Spread cancels out the second leg of the Calendar Spread.
				So we are still left with maintaining two open positions in this spread.  One for April $200 and one for March $195.  So you see
				both the expiration date and the strike price differ in this spread.  That is why it is called a Diagonal Spread.  Also you will 
				notice that the net debit spread has now turned into a net credit spread of $0.90.   
				<br /><br />
				The scenarios where you would make a profit is still the same as in the Calendar Spread you read about earlier.  The only different factor is
				the strike price is different now and you end up getting money for taking this position.
				<br /><br />
				Another thing to mention is that you do not have to manually enter into a Calendar Spread AND a Vertical Spread.  That would be too expensive
				in terms of commissions.  Your broker should have the ability to buy/sell Diagonal Spreads as one unit. The tables above were just for 
				illustrative purposes. 
				<br /><br />
			</div>
        </>
    )
}