import React from 'react';

export default function GoogleAdComponent(prop) {
    let slotID = "7942410497";
    if (prop.type === 'bigad') {
        slotID = "7942410497";
    }
    return (
        <>
            {/* Options728x90, created 4/30/10 */}
            <ins className="adsbygoogle"                
                style={{display:"inline-block", width:"728px", height:"90px"}}
                data-ad-client="ca-pub-4682642215710738"
                data-ad-slot={slotID}></ins>
            <script>
                (adsbygoogle = window.adsbygoogle || []).push({});
            </script>
        </>
    );
}