import React from 'react';
import GoogleAdComponent from './ads/GoogleAdComponent';
import AmazonAdComponent from './ads/AmazonAdComponent';

export default function Basics() {
    return (
        <>
 			<div id="left">
				<h1>
					Option Trading Strategies
					<br />
				</h1>
				
					So now that you have learned the basics from our basic Options Tutorial
					article and understood what options
					are and how to buy options, you've learned option trading strategy
					one.  That is, the strategy of simply buying Call and/or Put options.

					You might decide to buy options for various reasons.  For Call options,
					<ul>
						<li>
							Buy the option and sell it back when it appreciates.  So you
							buy and sell options as you would buy and sell stock.  The
							leverage is there so you have the potential to make
							more money.  But the potential is also there to lose
							more money.  Also you have more to make sure you are in a liquid
							market.  That is, the volume for the particular option contract is
							sufficiently large enough so you can get in and out with ease.  Trading
							options on well known companies and popular ETFs (like SPY) are good ways to go.
						</li>
						<AmazonAdComponent type='mid' />
						<li>
							Buy the option and hold it until it expires.  You may want the
							option to (pardon the pun) buy the stock if it does appreciate
							in the time frame you anticipate.   If it does, you just locked
							in a lower price.  But logically speaking it would add to your
							cost basis.
						</li>
						<li>
							You want to realize short term gain from an increase in the stock
							price but you don't have enough capital to invest in the stock
							itself or you don't want to invest so much in the stock.  Actually
							it is not just for short term gain since you can buy options with
							expiration dates of more than one year.
						</li>
					</ul>
                    <br /><br />
                    <GoogleAdComponent googleAdSlotID='8974568762' />
					<br /><br />
					For Put options:
					<ul>
						<li>
							Buy and sell the options like stock (same reason as in Call option
							above).
						</li>
						<li>
							You want to protect yourself against downward movements in your stock.
							If the stock were to take a sudden dramatic dip you will have unrealized
							losses which can be offset by the appreciation in your Put option.  On the
							other hand, if you stock keeps going up and your Put option expires worthless
							you will have incurred a meaningless cost.  It kind of feels like paying your
							health insurance premiums when you never see a doctor.
						</li>
					</ul>
				
				Those are your basic strategies. For slightly more advance ones, you can click the links
				on the right hand side under the menu Strategies Short Cut.
				</div>
        </>
    )
}