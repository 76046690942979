import React from 'react';
import AmazonAdComponent from './ads/AmazonAdComponent';

export default function BasicExamples() {
    return (
        <>
 			<div id="left">
				<h1>
					Option Examples
					<br />
				</h1>
				<h4>
					Example One - Basic Call
					<br />
				</h4>
					You did your research on Apple and decided that the stock price
					will increase dramatically soon. You want to invest approximately
					$2000, but the stock is very expensive (currently trading at
					$121.51). Your $2000 will only buy you about 16 shares. You want
					more leverage. So you look into options for Apple. You find that
					the May $125 Strike Price Call Option will cost you $470 per option
					(stated as $4.70). You stretch it a little and buy 5 option
					contracts costing you $2350. Sure thing the next day, Apple shares
					jump up to $126.17. You look at your option and its now worth
					$5.65.
					<br />
					<br />
					Do some quick math and your profit is $475. That is, 5 contracts *
					($565 - $470). Now if you had bought the stock directly, your
					profit would have been around $88 because remember that $2350 would
					have only bought you 19 shares. That is the power of options...if
					you get it right!
					<br />
					<br />

				<div class="block">
					<table width="100%" cellspacing="0" cellpadding="0" border="1" class="example">
						<thead>
							<tr>
								<td>
									Security
									<br />
								</td>
								<td>
									Cost
									<br />
								</td>
								<td>
									Profit
									<br />
								</td>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>
									AAPL stock
									<br />
								</td>
								<td>
									$121.51 * 19 =&nbsp; $2,308.69
									<br />
								</td>
								<td>
									 ($126.17 * 19) - $2,308.69 = $88.54
									<br />
								</td>
							</tr>
							<tr>
								<td>AAPL option<br /></td>
								<td>$470 * 5 = $2,350&nbsp;
								</td>
								<td>($565 * 5) - $2,350 = $475&nbsp;
								</td>
							</tr>
						</tbody>
					</table>
				</div>
					<br />
					This example shows a really nice profit.  But remember that you
					could have easily lost $475 if Apple did not perform well.  If
					you had bought the stock, you would have lost only $88.54 and you
					would have the luxury of holding onto the stock and wait for it to
					appreciate.  With options, you don't have the luxury of time.
					<br /><br />
					By the way, I did not make up these numbers. This is how the it was
					on April 23rd, 2008.
				<h4>
					<AmazonAdComponent type='mid' />
					Example Two - Basic Put
				</h4>
					You have a fairly bearish sentiment on VMWare. The ticker is VMW.
					You know that people are really cutting back on their technology
					budget these days so you believe VMW will have something bad to
					report on their earnings. VMW is currently trading around $32 so
					you go ahead and buy some put options. You scan the option table
					and decide to pick the May Put where the strike price is $31. It
					costs you $1.85 per contract (remember that you have to multiply by
					100 to find out what you will actually pay from your pocket!).
					Because it's only $1.85 you purchase 10 contracts. So your cost is
					$1,850 plus commission.
					<br />
					<br />
					The next day you find out how lucky you are (or smart you are
					depending on how you want to look at it). VMWare reports terrible
					earnings and their stock price dips to $26! You hate to see the
					company do bad but you love to make money. You option is now worth
					$5.4. I'm sure you can do the math by now. Your net profit is
					$3,550. Not bad huh?
					<div class="block">
					<table width="100%" cellspacing="0" cellpadding="0" border="1" class="example">
						<thead>
							<tr>
								<td>
									Security
									<br />
								</td>
								<td>
									Cost
									<br />
								</td>
								<td>
									Profit
									<br />
								</td>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>
									VMW Stock <br />
								</td>
								<td>$32 * 60 = $1,920<br />
								</td>
								<td>&nbsp; $1,920 - ($26 * 60) = $360
									<br />
								</td>
							</tr>
							<tr>
								<td>VMW option<br /></td>
								<td>$185 * 10 = $1,850&nbsp;
								</td>
								<td>&nbsp;($540 * 10 ) - $1,850 =&nbsp; $3,550
								</td>
							</tr>
						</tbody>
					</table>
				</div>
				<br />
				For comparison purposes, I've assumed you have shorted the
				stock.  So the first row in the table shows what the profit
				would have been if you closed your short position at $26/share.
				<h4>
					Example Three - Call gone wrong
				</h4>
					You got a nice tip about a little known company poised for a big
					bounce. The ticker is VOL. You buy some call options where the
					strike price is $7.5. You buy it at $0.45 (again multiply by 100 to
					get $45). So you sit and wait for the thunder to come rolling in.
					<br />
					<br />
					You soon discover that your tip was bad. VOL is not behaving as
					expected...in fact it is declining in stock price! you put in your
					sell order for the options but you can't sell them. Why? Because
					the volume is zero! Somebody sold you a bum option when the
					sentiment was high. But now nobody likes it and nobody is willing
					to take those options off your hands. You're stuck!
					<br />
					<br />
					But wait; you still have some time before expiration. Maybe the
					stock will pick up and people will start trading in these options
					again. Maybe...and maybe not. This is the risk you sometimes take
					when dealing with options. I suggest you stick to the ones where
					the volume is high; at least a few thousand a day. So you have a
					reasonable chance of getting out if you want.
			</div>
        </>
    )
}