import React from 'react';
import GoogleAdComponent from './ads/GoogleAdComponent';
import AmazonAdComponent from './ads/AmazonAdComponent';

export default function CoveredCall() {
    return (
        <>
 			<div id="left">
				<h1>
					Option Strategies
					<br />
				</h1>
				<h4>
					Covered Call
				</h4>
				Remember in the basic options section we talked about the most basic
				scenario of buying a Call option and that doing so assigned certain rights
				to you as the buyer? We'll now we're ready to talk about the guy on the other
				side of the transaction.  That is, the person selling the Call option.
				<br /><br />
				Lets say you own 1000 shares of Bank of America (BAC).  You bought it
				when it was $5/share.  Now it is trading considerably above that.  You've already
				made some money on paper and wouldn't mind selling it for a profit.  Instead
				of just selling the stock you could sell options on that stock.  Normally your opening
				transaction is a buy order, but in this case your opening transaction is to sell.
				<div class="block">
					<table width="100%" cellspacing="0" cellpadding="0" border="1" class="example">
						<thead>
							<tr>
								<td>
									Option
									<br />
								</td>
								<td>
									Strike Price
									<br />
								</td>
								<td>
									Profit
									<br />
								</td>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>
									BAC May '09
									<br />
								</td>
								<td>
									$9
									<br />
								</td>
								<td>
									 $1.18 * 10 * 100 = $1,180
									<br />
								</td>
							</tr>
							<tr>
								<td>
									BAC May '09
									<br />
								</td>
								<td>
									$10
									<br />
								</td>
								<td>
									 $0.78 * 10 * 100 = $780
									<br />
								</td>
							</tr>
						</tbody>
					</table>
				</div>
				<br />
				<AmazonAdComponent type='mid' />
				You would submit a "Sell to Open" order to create a short option
				position.  Lets pick a strike price from the table above.  The current
				market price is $9.10. and you think it is going to remain around this
				level for a short time so you decide to sell 10 Options for the $9 strike
				price.  So now you are on the hook.  You received the $1,180 up front.  That's
				cash in your account, but remember you've just given someone the right to
				buy 1000 shares from you at $9/share.  As long as you keep those 1000
				shares of BAC you're fine.
				<div class="block">
					Owning those 1000 shares is what makes this strategy a &quot;Covered Call.&quot;
					Otherwise your brokerage firm would make sure you had sufficient money
					in your margin account to cover the cost of buying 1000 share at the market
					price and selling them to the option buyer at $9/share.
				</div>
				There are a few ways to get you off the hook.
				<GoogleAdComponent googleAdSlotID='9563874105' />
				<ul>
					<li>
						The market price falls below $9 and stays there until expiration.
						In that case you keep your 1000 shares of BAC and you get
						to keep the $1,180 premium you received.
					</li>
					<li>
						The buyer exercises the option before expiration.  It is not that
						common but it can happen.  In that case your brokerage firm
						does an option assignment (for a fee that is) and sells your
						shares to that other person.  You get to keep your $1,180.
					</li>
					<li>
						At expiration, BAC is still above $9.  The option buyer's
						brokerage does an automatic assignment and your shares
						are sold at $9/share.  You still get to keep your $1,180.
					</li>
					<li>
						Before expiration, you decide you want to keep your shares of BAC.
						Maybe your opinion has changed and you now believe it could
						go up to $15 or more and you don't want to miss out on that.  You
						submit a &quot;Buy to Close&quot; order and buy back the options
						to close the trade.  If the option is worth less than the original $1.18 you
						would realize a small profit by buying it back at a lower price.
						But you could also realize a loss if the price is above $1.18.
					</li>
				</ul>

				<br /><br />

			</div>
        </>
    )
}