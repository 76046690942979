import React, { useState } from 'react';
import clsx from 'clsx';
import { makeStyles } from '@material-ui/core/styles';
import CssBaseline from '@material-ui/core/CssBaseline';
import Drawer from '@material-ui/core/Drawer';
import Box from '@material-ui/core/Box';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import List from '@material-ui/core/List';
import Typography from '@material-ui/core/Typography';
import Divider from '@material-ui/core/Divider';
import IconButton from '@material-ui/core/IconButton';
import Container from '@material-ui/core/Container';
import Grid from '@material-ui/core/Grid';
import Link from '@material-ui/core/Link';
import MenuIcon from '@material-ui/icons/Menu';
import ChevronLeftIcon from '@material-ui/icons/ChevronLeft';
import { mainListItems, secondaryBasicsListItems, secondaryStrategiesListItems } from './listItems';
import { PATHS } from '../Paths';
import GoogleAdComponent from '../component/ads/GoogleAdComponent';
import AmazonAdComponent from '../component/ads/AmazonAdComponent';
import Disclaimer from '../component/Disclaimer';
import Home from '../component/Home';
import Basics from '../component/Basics';
import Strategies from '../component/Strategies';
import CoveredCall from '../component/CoveredCall';
import CalendarSpread from '../component/CalendarSpread';
import DiagonalSpread from '../component/DiagonalSpread';
import IronCondorSpread from '../component/IronCondorSpread';
import MiniOptions from '../component/MiniOptions';
import OptionCheatSheet from '../component/OptionCheatSheet';
import ProtectivePut from '../component/ProtectivePut';
import SecuredPut from '../component/SecuredPut';
import Strangle from '../component/Strangle';
import VerticalSpread from '../component/VerticalSpread';
import WeeklyOptions from '../component/WeeklyOptions';
import BasicExamples from '../component/BasicExamples';

function Copyright() {
  return (
    <>
    <Typography variant="body2" color="textSecondary" align="center">
      {'Copyright © '}
      <Link color="inherit" href="https://material-ui.com/">
        Your Website
      </Link>{' '}
      {new Date().getFullYear()}
      {'.'}
    </Typography>
    <Disclaimer />
    </>
  );
}

const drawerWidth = 240;

const useStyles = makeStyles((theme) => ({
  root: {
    display: 'flex',
  },
  toolbar: {
    paddingRight: 24, // keep right padding when drawer closed
  },
  toolbarIcon: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'flex-end',
    padding: '0 8px',
    ...theme.mixins.toolbar,
  },
  appBar: {
    zIndex: theme.zIndex.drawer + 1,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
  },
  appBarShift: {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  menuButton: {
    marginRight: 36,
  },
  menuButtonHidden: {
    display: 'none',
  },
  title: {
    flexGrow: 1,
  },
  drawerPaper: {
    position: 'relative',
    whiteSpace: 'nowrap',
    width: drawerWidth,
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  },
  drawerPaperClose: {
    overflowX: 'hidden',
    transition: theme.transitions.create('width', {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.leavingScreen,
    }),
    width: theme.spacing(7),
    [theme.breakpoints.up('sm')]: {
      width: theme.spacing(9),
    },
  },
  appBarSpacer: theme.mixins.toolbar,
  content: {
    flexGrow: 1,
    //height: '100vh',
    overflow: 'auto',
  },
  container: {
    paddingTop: theme.spacing(4),
    paddingBottom: theme.spacing(4),
  },
  paper: {
    padding: theme.spacing(2),
    display: 'flex',
    overflow: 'auto',
    flexDirection: 'column',
  },
  fixedHeight: {
    height: 240,
  },
}));

function getNavComponent(navComp) {
  switch (navComp) {
    case PATHS.HOME:
      return <Home />
    case PATHS.BASICS:
      return <Basics />
    case PATHS.STRATEGIES:
      return <Strategies />
    case PATHS.STRANGLE:
      return <Strangle />
    case PATHS.VERTICALSPREAD:
      return <VerticalSpread />
    case PATHS.WEEKLYOPTIONS:
      return <WeeklyOptions />
    case PATHS.MINIOPTIONS:
      return <MiniOptions />
    case PATHS.CHEATSHEET:
      return <OptionCheatSheet />
    case PATHS.PROTECTIVEPUT:
      return <ProtectivePut />
    case PATHS.SECUREDPUT:
      return <SecuredPut />
    case PATHS.BASICEXAMPLES:
      return <BasicExamples />
    case PATHS.IRONCONDORSPREAD:
      return <IronCondorSpread />
    case PATHS.DIAGONALSPREAD:
      return <DiagonalSpread />
    case PATHS.CALENDARSPREAD:
      return <CalendarSpread />
    case PATHS.COVEREDCALL:
      return <CoveredCall />
    default :
      return <></>
  }
}

export default function Dashboard() {


  const [currNav, setCurrNav] = useState(PATHS.HOME);
  
  const classes = useStyles();
  const [open, setOpen] = React.useState(true);
  const handleDrawerOpen = () => {
    setOpen(true);
  };
  const handleDrawerClose = () => {
    setOpen(false);
  };
  // const fixedHeightPaper = clsx(classes.paper, classes.fixedHeight);

  return (
    <div className={classes.root}>
      <CssBaseline />
      <AppBar position="absolute" className={clsx(classes.appBar, open && classes.appBarShift)}>
        <Toolbar className={classes.toolbar}>
          <IconButton
            edge="start"
            color="inherit"
            aria-label="open drawer"
            onClick={handleDrawerOpen}
            className={clsx(classes.menuButton, open && classes.menuButtonHidden)}
          >
            <MenuIcon />
          </IconButton>
          <Typography component="h1" variant="h6" color="inherit" noWrap className={classes.title}>
            Options For Dummies
          </Typography>
          <Typography variant="subtitle1" color="inherit" noWrap className={classes.title}>
            How to trade stock options.  From the basics to advanced options strategies.
          </Typography>
        </Toolbar>
      </AppBar>
      <Drawer
        variant="permanent"
        classes={{
          paper: clsx(classes.drawerPaper, !open && classes.drawerPaperClose),
        }}
        open={open}
      >
        <div className={classes.toolbarIcon}>
          <IconButton onClick={handleDrawerClose}>
            <ChevronLeftIcon />
          </IconButton>
        </div>
        <Divider />
        <List>{mainListItems(setCurrNav)}</List>
        <Divider />
        <List>{secondaryBasicsListItems(setCurrNav)}</List>
        <Divider />
        <List>{secondaryStrategiesListItems(setCurrNav)}</List>
      </Drawer>
      <main className={classes.content}>
        <div className={classes.appBarSpacer} />
        <Container maxWidth="lg" className={classes.container}>
          <Grid container spacing={3}>
            {/* Chart */}
            {/* Recent Orders */}
            <Grid item xs={12}>
              <AmazonAdComponent />
              <GoogleAdComponent googleSlotID='7942410497' />
              {getNavComponent(currNav)}
              <GoogleAdComponent googleSlotID='7942410497' />
            </Grid>
          </Grid>
          <Box pt={4}>
            <Copyright />
          </Box>
        </Container>
      </main>
    </div>
  );
}
