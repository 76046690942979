import React from 'react';
import GoogleAdComponent from './ads/GoogleAdComponent';
import AmazonAdComponent from './ads/AmazonAdComponent';

export default function CalendarSpread() {
    return (
        <>
 			<div id="left">

				<h1>
					Option Strategies
					<br />
				</h1>
				<h4>Calendar Spread
				</h4>
				This strategy is another type of spread.  Now remember that spreads
				get their name from the fact that your overall position involves one
				long and one short position.  In the case of Vertical spreads, the
				difference was between the strike price of the two.  Now with Calendar
				Spreads, the spread is mainly due to the difference in the expiration
				month.
				<br /><br />
				Calendar Spreads come in two flavours: Horizontal and Diagonal.

				<div class="block">
					If you're wondering what's up with the Vertical, Horizontal, and Diagonal terminology,
					imagine a graph where the strike price is on the Y-axis and the expiration months are on the X-axis.  If
					you vary your long and short options along the Y-axis, it's vertical.  If you vary
					along the X-axis, it's horizontal.  And you can guess that if you vary along both then
					it's diagonal.
				</div>
				<br />Buying Horizontal Calendar Spreads is usually a net debit trade and involves obtaining a long call option position and a short call option
				position where the difference is in the expiration month.  The long position is in the back end month 
				and the short position is in the front end month.  The front end month is considered to be the expiration month
				closest to you; whereas the back end month is the expiration month furthest from you.  You typically employ this
				strategy in a period where the volatility is low at first but then increases later.  So you can see that this is 
				a very advanced strategy and you really need to know what you're doing. 
				
				Lets take an example.  So you sell one IBM $125 Nov option and buy one IBM $125 Dec option.  Everything stays 
				the same except the expiration month.  What benefit does this give you?  Well here are some scenarios to 
				give you an idea:
				<AmazonAdComponent type='mid' />
				<ol>
					<li>The most common expectation when using a <strong>Calendar Spread Strategy</strong> is to close
					    out the position at a point in time when the potential profit will be at it's maximum.  Consider
						the IBM option; if the current price is $120, you want the price to rise to exactly $125 at the close of
						business on option expiration friday of November.  This is because 1) the November option will expire 
						worthless and 2) the time value on the December option will be at it's peak at that time.  From this point on
						the time value will decay at a faster rate.  <br /><br />
						Lets take a step back and see what we've actually done here because it is important to understand what is happening.
						You are basically making a bet on what IBM's stock price will be on expiration date in November.  It is a slightly bullish bet, 
						meaning you suspect the price will rise. You want to profit from this bullish sentiment by buying a call option
						but you don't want to spend the money to maintain a purely bullish position by buying just plain call options.  So you finance the 
						cost of one Call option by selling another Call option.  You make money by taking advantage of the time decay on options.  
						<br /><br />
					</li>
					<li>You sell one IBM $125 Nov Call option for $3.  At the same time you buy one IBM $125 Dec
					    option for $4.  If you're very good and timed the market perfectly (or if you just get
					    lucky), what will happen is the stock price will remain below $125 by Nov expiration.
					    In that case your November option expires worthless. After that expires, IBM jumps up in price to above $126. 
						By selling the front end month, you lowered your break even point  and now you see nothing but profits above $126 
						on the December option because that option is now In-the-Money.
						<br /><br />
					</li>
					<GoogleAdComponent googleAdSlotID='9563874105' />
					<li>Consider the same scenario with the IBM options.  But this time, the stock moves against
					    you.  You miscalculate and IBM shoots up o $130 the next few days and is very bullish.
					    Now you are at risk of getting assigned on the front month option that you sold. Remember
					    when you sell a call option, you give someone else the right to buy that stock from
					    you at $125/share.  Which means you'll need 100 <small>x</small> <i>n</i> shares of IBM
					    in your account.  Luckily you have bought the same number options for the back end month
					    at the same strike price.  So you can exercise that option against someone else.  That
					    means you are pretty much even.  You sold someone IBM shares at $125 but you also bought
					    IBM shares at $125.  The only loss you incurred is the commission fees and the original
					    purchase price of the Calendar Spread.
						<br /><br />
					</li>
					<li>Lastly, you can simply buy and sell calendar spreads like you would a stock.  When you
					    create a position, you aren't literally selling and buying options separately.  There is a
					    market for Calendar Spreads with predefined expiration months.  And you buy and sell the
					    entire spread in one single transaction.  If you perceive near term pessimism on IBM you
					    can take advantage by buying the spread.  What happens is that after a while people start
					    to realize that things aren't as bad and the longer term option starts gaining in value. The
					    entire spread position will be worth more than what you originally paid for it and you simply
					    close the spread by selling it again.
					</li>
				</ol>
				The whole idea of buying Calendar Spreads are to take advantage of short term price discrepencies and time decay.
                Therefore you have to follow your market(s) very carefully and diligently. Calendar Spreads are
                an advanced options strategy, so be careful and start with a mininum investment amount.
				<br /><br />
			</div>
        </>
    )
}