import React from 'react';
import Typography from '@material-ui/core/Typography';

export default function Disclaimer() {
    return (
        <>
            <Typography variant="caption" color="textSecondary" align="center">
                The entire content of this website is meant for educational purposes only.  It should not be construed as
                investment advice.  And I am certainly not making any claims about the profitability of options trading.  Options
                are inherently risky and you should carefully consider the risks before investing any money.  The examples
                given are not necessarily using real world numbers as it is used for illustrative purposes.
                Any person(s) reading the material on this website should consult their own professional investment
                adviser before investing in options or any other security.
            </Typography>
        </>

    );
}