import React from 'react';

export default function AmazonAdComponent(prop) {
    //assume its top unless otherwise stated
    let divID = "amzn-assoc-ad-b50de289-9be5-45b3-a5ed-7fff98699fb0";
    if (prop.type === 'top') {
        divID = "amzn-assoc-ad-b50de289-9be5-45b3-a5ed-7fff98699fb0";
    } else if (prop.type === "mid") {
        divID = "amzn-assoc-ad-d6bf6685-7933-4d55-91df-61c3e7cdb3bd"
    }

    return (
        <>
			<div id={divID}></div>
            {/* <script async src="//z-na.amazon-adsystem.com/widgets/onejs?MarketPlace=US&adInstanceId=b50de289-9be5-45b3-a5ed-7fff98699fb0"></script> */}
        </>
    );
}