import React from 'react';
import AmazonAdComponent from './ads/AmazonAdComponent';

export default function ProtectivePut() {
    return (
        <>
 			<div id="left">
				<h1>
					Option Strategies
					<br />
				</h1>
				<h4>Protective Put
				</h4>
				In this strategy we start out by owning the 1000 shares of Bank of America
				(BAC) just like in the covered call strategy.  But in this case you may be
				more concerned that the stock price will dip in the near term.  You still
				like to own BAC however.  What do you do?  You buy a simple Put Option (10
				options to be exact).  It reduces the profit you've already made in BAC on paper
				because of the cost of the 10 options, but you protect yourself in case BAC
				drops suddenly.  How?  Remember that Put options gives you the right to sell
				your shares at the strike price.
				<br /><br />
				<AmazonAdComponent type='mid' />
				So if BAC is currently trading at $9/share you can buy 10 options at the
				$8 strike price.   If BAC reports really bad earnings or it looks like BAC
				is in trouble, the stock price may shoot down to $4/share.  Now without the options
				you would have had some huge unrealized losses.  But with the Put option, you
				have the right to sell your shares at $8/share.  Saving you a loss of $4,000.
				($8 strike price - $4 current trading price multiplied by 1000 shares).


				<br /><br />
			</div>
        </>
    )
}