import React from 'react';
import GoogleAdComponent from './ads/GoogleAdComponent';
import AmazonAdComponent from './ads/AmazonAdComponent';

export default function WeeklyOptions() {
    return (
        <>
 			<div id="left">
				<h1>
					Weekly Options
					<br />
				</h1>
				<h4>
					What is a Weekly Option?
					<br />
				</h4>
					Now that you know all about regular options, which up until this point, have been monthly or even yearly options, 
					its time we introduced the <strong>Weekly Option.</strong>
					As you might guess, a weekly option is an option contract which expires on a weekly basis!  Here are some basic rules surrounding
					Weekly options: 
					<div class="block">
						<ul>
				            <li>Typically listed on a Thursday and expire on the following Friday.  The exception is if the Friday is the third Friday of the month, then it expires on Thursday</li>
				            <li>Not available on all stocks. Some stocks which have monthly options will not have Weekly options listed.  It just depends on the stock. </li>
							<li>The full list of available weekly options are available at the CBOE website.  Click <a href="http://www.cboe.com/publish/weelkysmf/weeklysmf.xls" target="_blank" rel="noopener noreferrer">here</a></li>
							<li>The last day to trade the Weekly depends on whether the option is am-settled or pm-settled.</li>
						</ul>
				    </div>
					<br />
					<AmazonAdComponent type='mid' />
					So the concept of am/pm settled has not been discussed before.  Basically, if the option is pm-settled, the last day to trade the option is 
					the day of expiration.  For example, a Weekly Verizon option expiring on Feb 27 (which is a Friday) can be traded up until the close of trading day Feb 27. 
					<br />
					<br />
					PM-settlement means that the settlement of the option is based on the final price of the underlying stock.  In our example, VZ.  The final price is determined
					on the close of the trading day.  So if the final price of VZ is determined at the close of Friday, Feb 27, the option on VZ will also settle on 
					the close of Friday, Feb 27.
					<br />
					<br />
					<GoogleAdComponent googleAdSlotID='8974568762' />
					Now this differs from am-settlement.  In am-settled options, the value of the underlying instrument is determined on the opening of the trading day.  In that case,
					the option is also settled on the opening of the trading.  So what does this mean to you as a trader?  It means the last time you can trade that option is the day
					before the expiration date!  So if you need to get out of a trade, you must take this into account.  
					<br />
					<br />
					Okay, so far so good right?  Well not exactly.  The big question is how do you know whether the option will be am or pm settled?  Since the option settlement depends
					on the underlying instrument's settlement then you need to understand how the underlying is settled.  Notice that I started using the word "instrument" instead of "stock".  That is
					because not all options are based on Stocks.  Some are based on Index-based securities.   So a good rule of thumb is that options based on stock (equity options) are pm-settled,
					while options based on indexes are am-settled (although not always true).  As you become an experienced trader, you will learn to differentiate between the two.  But the rule thumb 
					is a good starting point. 
			</div>
        </>
    )
}