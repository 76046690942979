import React from 'react';
import GoogleAdComponent from './ads/GoogleAdComponent';
import AmazonAdComponent from './ads/AmazonAdComponent';

export default function Strangle() {
    return (
        <>
 			<div id="left">

				<h1>
					Option Strategies
					<br />
				</h1>
				<h4>Strangle
				</h4>
                This is an options combining strategy containing two legs. In the first leg
                you buy one or more Call Options contracts and in the second leg you buy the same number
                of Put Option contracts.  Both legs will have the same expiration dates and each strike
                price is equidistant from the underlying stock's current price but with different
                out of the money strike prices. You would make a profit with this strategy when there is a
                dramatic price move in either direction.  In other words you are expecting a lot of volatility
                but you are not sure whether your underlying stock will go up or down.  In this strategy
                your risk is relatively low as your maximum loss is the amount you invested in case the price
                does not move much in either direction. You can exercise this strategy in two flavors:
				<br /><br />
				<ol>
					<li><strong>Long (Buy) Strangle</strong>: A long strangle is where you <strong>BUY</strong> an equal number of out of
						the money Put Options and out of the money Call Options with different strike prices
						but the same expiration date. It's a low risk strategy with a potentially unlimited reward.
						<br /><br />
						<strong>When to enter</strong>: if a stock is too volatile over a period, particulary towards the
			            expiration date in mind.
						<br /><br />
						Let's take an example of stock XYZ which is currently trading at $100 a share:
						<div class="block">
							<table width="100%" cellspacing="0" cellpadding="0" border="1" class="example">
								<thead>
									<tr>
										<td>
											Call Premium
											<br />
										</td>
										<td>
											Strike Price
											<br />
										</td>
										<td>
											Put Premium
											<br />
										</td>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>
											$9
											<br />
										</td>
										<td>
											$90*
										</td>
										<td>
											 $0.5*
											<br />
										</td>
									</tr>
									<tr>
										<td>
											$3
											<br />
										</td>
										<td>
											$100
											<br />
										</td>
										<td>
											 $1
											<br />
										</td>
									</tr>
									<tr>
										<td>
											$1*
											<br />
										</td>
										<td>
											$110*
											<br />
										</td>
										<td>
											 $7
											<br />
										</td>
									</tr>
								</tbody>
							</table>
						</div>
						<font size="1">*=The two legs of the strategy</font>
						<br /><br />
						<AmazonAdComponent type='mid' />
						If you buy 1 Put Option at strike price $90.00 and buy 1 Call Option at $110.00 strike price, your total cost of
						investment would be $150.00 ($0.5 * 100 + $1.00 * 100).
						<br /><br />
 						<strong>Max. Loss</strong> = Net Debit = $0.5 + $1.00 = $1.50<br /> <br />
						<strong>Upper Break Even</strong> = Call Strike Price + Net Debit = $110.00 + $1.50 = $111.50<br /><br />
						<strong>Lower Break Even</strong> = Put Strike Price - Net Debit = $90.00 - $1.50 = $88.50<br /><br />

						<strong>Profit Scenario</strong>: If the price of the stock goes down to $80.00.
						The Call Option would be worthless and your loss would be $100.00. Now, your Put Option is worth close to
						$1,000.00. Your net profit would be $850.00 ($1000.00 - $50.00 - $100.00).
						<br /><br />
            <strong>Profit Scenario</strong>: Another profit scenario is if the stock goes up $120.00.  The Put Option expires
            worthless, but the Call Option is now worth $1,000.00.  Again, your net profit would be $850.00.
            <br /><br />
						<strong>Loss Scenario</strong>: if the stock price does not move much and stays near $100.00 then both your call and
						Put Options would be worthless on expiration and you would lose a maximum of $150.00.
					</li>
					<br />
					<li><strong>Short (Sell) Strangle</strong>: A short strangle option is to <strong>SELL</strong> an equal number of out of the money Put Options and
						out of the money Call Options with different strike prices but of same expiration date. It's a medium to high risk strategy
						with a limited reward.
 						<br /><br />
						<strong>When to enter</strong>: if a stock is static over a period.
 						<br /><br />
						 <GoogleAdComponent googleAdSlotID='9563874105' />
						Let's take an example of stock XYZ which is currently trading at $100 a share
						<div class="block">
							<table width="100%" cellspacing="0" cellpadding="0" border="1" class="example">
								<thead>
									<tr>
										<td>
											Call Premium
											<br />
										</td>
										<td>
											Strike Price
											<br />
										</td>
										<td>
											Put Premium
											<br />
										</td>
									</tr>
								</thead>
								<tbody>
									<tr>
										<td>
											$9
											<br />
										</td>
										<td>
											$90*
										</td>
										<td>
											 $0.5*
											<br />
										</td>
									</tr>
									<tr>
										<td>
											$3
											<br />
										</td>
										<td>
											$100
											<br />
										</td>
										<td>
											 $1
											<br />
										</td>
									</tr>
									<tr>
										<td>
											$1*
											<br />
										</td>
										<td>
											$110*
											<br />
										</td>
										<td>
											 $7
											<br />
										</td>
									</tr>
								</tbody>
							</table>
						</div>
						<font size="1">*=The two legs of the strategy</font>
						<br /><br />
						If you sell 1 Put Option at strike price $90.00 and sell 1 Call Option at $110.00 strike price, your total gain
						by selling those two options would be $150.00 ($0.50 * 100 + $1.00 * 100).
						<br /><br />
						<strong>Max. Profit</strong> = Net Credit = $0.50 + $1.00 = $1.50<br /><br />
						<strong>Upper Break Even</strong> = Call Strike + Net Credit = $110.00 + $1.50 = $111.50<br /><br />
						<strong>Lower Break Even</strong> = Put Strike - Net Credit = $90.00 - $1.50 = $88.50<br /><br />

						<strong>Profit Scenario</strong>: On expiration, if XYZ stock is still trading at $100,
						both the put and the call expire worthless and you get to keep the entire premium gained of $150 as profit. So,
						the total premium gained is the maximum profit that you can get in this strategy.
						<br /><br />
						<strong>Loss Scenario</strong>: If XYZ stock goes to $120 on expiration, the put will expire worthless but the call expires
						in the money with a loss of $1,000. Subtracting the initial premium gained of $150, you net loss would be
						$850.  But theoretically your loss is unlimited as there is no upper limit on the price of the stock.
                        <br /><br />
                        <strong>Loss Scenario</strong>: Another loss scenario is if the stock drops to $80.  In this case, your Call Option
                        expires worthless, but your Put Option is in the money.  The numbers would be the same in this example; i.e., a net
                        loss of $850.  But your loss is not theoretically unlimited because the stock can only drop to $0.
					</li>
				</ol>
			</div>
        </>
    )
}