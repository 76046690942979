import React from 'react';
import AmazonAdComponent from './ads/AmazonAdComponent';
import GreenUp_Image from '../images/green_up.jpg';
import RedDown_Image from '../images/red_down.jpg';
import OptionNamingConvention_Image from '../images/Option Naming Convention.png';

export default function OptionCheatSheet() {
    return (
        <>
 			<div id="left">

				<h1>
					Option Cheat Sheet
					<br />
				</h1>
				<h4>
					Profit Table
					<br />
				</h4>
				<table BORDER={1} CELLSPACING={0} CELLPADDING={0} WIDth="100%">
					<tr>
						<th>
							Option Style
						</th>
						<th>
							Opening Transaction
						</th>
						<th>
							Profit When
						</th>
					</tr>
					<tr>
						<td>
							Call
						</td>
						<td>
							Buy
						</td>
						<td>
							price increases
							<img src={GreenUp_Image} alt="green up profit" />
						</td>
					</tr>
					<tr>
						<td>
							Call
						</td>
						<td>
							Sell
						</td>
						<td>
							price decreases
							<img src={RedDown_Image} alt="red down decline" />
						</td>
					</tr>
					<tr>
						<td>
							Put
						</td>
						<td>
							Buy
						</td>
						<td>
							price decreases
							<img src={RedDown_Image} alt="red down decline" />
						</td>
					</tr>
					<tr>
						<td>
							Put
						</td>
						<td>
							Sell
						</td>
						<td>
							price increases
							<img src={GreenUp_Image} alt="green up profit" />
						</td>
					</tr>
				</table>

				<h4>
					Important Definitions
				</h4>
				<ol>
					<li>
						<strong>Call option</strong> - Contract that gives the buyer the
						right to buy stock from the seller at the low strike price and
						sell the same stock to the market for the higher market price.
					</li>
					<li>
						<strong>Put option</strong> - Contract that gives the buyer the
						right to buy the stock from the market at the lower market price
						and then sell the same stock to the seller at the high strike
						price.
					</li>
					<li>
						<strong>Strike Price</strong> - The price where the underlying
						stock would have to be (or better) in order to exercise the
						option.
					</li>
					<li>
						<strong>Option Expiration</strong> - Stated as the month and year
						in which the option contract expires. It is typically the close of
						trading on the third Friday of the month.
					</li>
					<li>
						<strong>In the money</strong> - Term used to describe an option
						where the market price of the underlying stock is above (for Call
						Option) or below (for Put Option) the strike price.
					</li>
					<li>
						<strong>Intrinsic value</strong> - That portion of the option
						premium that correlates to how much it is "in the money".
					</li>
					<li>
						<strong>Time value</strong> - That portion of the option premium
						that is not Intrinsic Value. The farther away the current date is
						from the expiration date, the more Time Value you have.
					</li>
					<li>
						<strong>Net Debit</strong> - Type of transaction where you end up
						paying money to either open or close a position.
					</li>
					<li>
						<strong>Net Credit</strong> - Type of transaction where you receive
						money to open or close a position.
					</li>
				</ol>
					<AmazonAdComponent type='mid' />
				<h4>
					Option Naming Scheme
				</h4>
				The option naming standards are surprisingly easy to understand.  Lets break it down with an example:
				<br /><br />
				<strong>IBM200619C00050000</strong>
				<br /><br />
				Okay lots symbols.  But it follows this pattern:  
				<br /><br />
				<i>[<strong>symbol</strong>][<strong>Year</strong>][<strong>Month</strong>][<strong>Day</strong>][<strong>Call or Put</strong>][<strong>strike price</strong>]</i>
				<br /><br />
				<table BORDER={1} CELLSPACING={0} CELLPADDING={0}>
					<tr>
						<th>
							Pattern
						</th>
						<th>
							Description
						</th>
					</tr>
					<tr>
						<td>
							Symbol
						</td>
						<td>
							The stock symbole.  Up to 6 characters if required.  Normally 3 to 4. 
						</td>
					</tr>
					<tr>
						<td>
							Year
						</td>
						<td>
							2 digit format for year.  E.g., 20 for 2020.
						</td>
					</tr>
					<tr>
						<td>
							Month
						</td>
						<td>
							2 digit format for month.  E.g., 01 for January.
						</td>
					</tr>
					<tr>
						<td>
							Day
						</td>
						<td>
							2 digit format for the day within the month.
						</td>
					</tr>
					<tr>
						<td>
							Call or Put
						</td>
						<td>
							<strong>C</strong> for Call.  <strong>P</strong> for put
						</td>
					</tr>
					<tr>
						<td>
							Strike Price
						</td>
						<td>
							8 digits for the strike price.  Imagine there is a decimal after the 5th digit.  In our example above, '00050000' 
							would be $50.  Another example: IBM210115C00110000 would be for strike price $110.
						</td>
					</tr>
				</table>
				<br /><br />
				So in our above above example for <strong>IBM200619C00050000</strong>, it's shown in our graphic below.
				<br /><br />
				<img class="imagesize" src={OptionNamingConvention_Image} alt="Option Naming Convention" />

				<div style={{display: 'none'}}>

					<h4>
						Option Naming Scheme - Prior to 2010
					</h4>
					The table below will show you how to interpret the option chains you
					see on any website which lists option chains. This first table will
					list all the codes used to represent the expiration month. So if you
					see an IBM option quote for IBMEA.X you will notice of course that
					the first 3 characters are for the IBM ticker itself. The next
					character is "E" which you will find in the table below.
					<br />
					<br />
					<table BORDER={1} CELLSPACING={0} CELLPADDING={0}>
						<tr>
							<th>
								Month
							</th>
							<th>
								Call
							</th>

							<th>
								Put
							</th>
						</tr>
						<tr>
							<td>
								January
							</td>
							<td>
								A
							</td>
							<td>
								M
							</td>
						</tr>
						<tr>
							<td>
								February
							</td>
							<td>
								B
							</td>
							<td>
								N
							</td>

						</tr>
						<tr>
							<td>
								March
							</td>
							<td>
								C
							</td>
							<td>
								O
							</td>
						</tr>
						<tr>
							<td>
								April
							</td>
							<td>
								D
							</td>
							<td>
								P
							</td>
						</tr>

						<tr>
							<td>
								May
							</td>
							<td>
								E
							</td>
							<td>
								Q
							</td>
						</tr>
						<tr>
							<td>
								June
							</td>
							<td>
								F
							</td>
							<td>
								R
							</td>
						</tr>
						<tr>

							<td>
								July
							</td>
							<td>
								G
							</td>
							<td>
								S
							</td>
						</tr>
						<tr>
							<td>
								August
							</td>
							<td>
								H
							</td>
							<td>
								T
							</td>
						</tr>
						<tr>
							<td>
								September
							</td>

							<td>
								I
							</td>
							<td>
								U
							</td>
						</tr>
						<tr>
							<td>
								October
							</td>
							<td>
								J
							</td>
							<td>
								V
							</td>
						</tr>
						<tr>
							<td>
								November
							</td>
							<td>
								K
							</td>

							<td>
								W
							</td>
						</tr>
						<tr>
							<td>
								December
							</td>
							<td>
								L
							</td>
							<td>
								X
							</td>
						</tr>
					</table>
					<br />
					<br />
					The table below will show you how to interpret the rest of the IBM
					option symbol IBMEA.X.  We know that IBM is the company ticker and that "E"
					is the expiration month.  But what about "A"?  Well you can see from the chart
					below that "A" represents the strike price $105.  That last part of the quote
					(".X") is used by some people to identify that security as an option type.
					<br />
					<br />
					<table BORDER={1} CELLSPACING={0} CELLPADDING={0} width="100%">
						<tr>
							<th align="center">
								Code
							</th>
							<th align="center" colspan="6">
								Strike Prices
							</th>
						</tr>
						<tr>
							<td align="center">
								<b>A</b>
							</td>
							<td align="center">
								5
							</td>
							<td align="center">
								105
							</td>

							<td align="center">
								205
							</td>
							<td align="center">
								305
							</td>
							<td align="center">
								405
							</td>
							<td align="center">
								505
							</td>
						</tr>
						<tr>
							<td align="center">
								<b>B</b>
							</td>
							<td align="center">
								10
							</td>
							<td align="center">
								110
							</td>
							<td align="center">
								210
							</td>
							<td align="center">
								310
							</td>
							<td align="center">
								410
							</td>

							<td align="center">
								510
							</td>
						</tr>

						<tr>
							<td align="center">
								<b>C</b>
							</td>
							<td align="center">
								15
							</td>
							<td align="center">
								115
							</td>
							<td align="center">
								215
							</td>
							<td align="center">
								315
							</td>
							<td align="center">
								415
							</td>
							<td align="center">
								515
							</td>
						</tr>
						<tr>
							<td align="center">
								<b>D</b>
							</td>
							<td align="center">
								20
							</td>

							<td align="center">
								120
							</td>
							<td align="center">
								220
							</td>
							<td align="center">
								320
							</td>
							<td align="center">
								420
							</td>
							<td align="center">
								520
							</td>
						</tr>
						<tr>
							<td align="center">
								<b>E</b>
							</td>
							<td align="center">
								25
							</td>
							<td align="center">
								125
							</td>
							<td align="center">
								225
							</td>
							<td align="center">
								325
							</td>

							<td align="center">
								425
							</td>
							<td align="center">
								525
							</td>
						</tr>
						<tr>
							<td align="center">
								<b>F</b>
							</td>
							<td align="center">
								30
							</td>
							<td align="center">
								130
							</td>
							<td align="center">
								230
							</td>
							<td align="center">
								330
							</td>
							<td align="center">
								430
							</td>
							<td align="center">
								530
							</td>
						</tr>
						<tr>
							<td align="center">
								<b>G</b>
							</td>
							<td align="center">
								35
							</td>

							<td align="center">
								135
							</td>
							<td align="center">
								235
							</td>
							<td align="center">
								335
							</td>
							<td align="center">
								435
							</td>
							<td align="center">
								535
							</td>
						</tr>
						<tr>
							<td align="center">
								<b>H</b>
							</td>
							<td align="center">
								40
							</td>
							<td align="center">
								140
							</td>
							<td align="center">
								240
							</td>
							<td align="center">
								340
							</td>

							<td align="center">
								440
							</td>
							<td align="center">
								540
							</td>
						</tr>
						<tr>
							<td align="center">
								<b>I</b>
							</td>
							<td align="center">
								45
							</td>
							<td align="center">
								145
							</td>
							<td align="center">
								245
							</td>
							<td align="center">
								345
							</td>
							<td align="center">
								445
							</td>
							<td align="center">
								545
							</td>
						</tr>
						<tr>
							<td align="center">
								<b>J</b>
							</td>
							<td align="center">
								50
							</td>

							<td align="center">
								150
							</td>
							<td align="center">
								250
							</td>
							<td align="center">
								350
							</td>
							<td align="center">
								450
							</td>
							<td align="center">
								550
							</td>
						</tr>
						<tr>
							<td align="center">
								<b>K</b>
							</td>
							<td align="center">
								55
							</td>
							<td align="center">
								155
							</td>
							<td align="center">
								255
							</td>
							<td align="center">
								355
							</td>

							<td align="center">
								455
							</td>
							<td align="center">
								555
							</td>
						</tr>
						<tr>
							<td align="center">
								<b>L</b>
							</td>
							<td align="center">
								60
							</td>
							<td align="center">
								160
							</td>
							<td align="center">
								260
							</td>
							<td align="center">
								360
							</td>
							<td align="center">
								460
							</td>
							<td align="center">
								560
							</td>
						</tr>
						<tr>
							<td align="center">
								<b>M</b>
							</td>
							<td align="center">
								65
							</td>

							<td align="center">
								165
							</td>
							<td align="center">
								265
							</td>
							<td align="center">
								365
							</td>
							<td align="center">
								465
							</td>
							<td align="center">
								565
							</td>
						</tr>
						<tr>

							<td align="center">
								<b>N</b>
							</td>
							<td align="center">
								70
							</td>
							<td align="center">
								170
							</td>
							<td align="center">
								270
							</td>
							<td align="center">
								370
							</td>
							<td align="center">
								470
							</td>
							<td align="center">
								570
							</td>

						</tr>
						<tr>

							<td align="center">
								<b>O</b>
							</td>
							<td align="center">
								75
							</td>
							<td align="center">
								175
							</td>
							<td align="center">
								275
							</td>
							<td align="center">
								375
							</td>
							<td align="center">
								475
							</td>
							<td align="center">
								575
							</td>

						</tr>
						<tr>

							<td align="center">
								<b>P</b>
							</td>
							<td align="center">
								80
							</td>
							<td align="center">
								180
							</td>
							<td align="center">
								280
							</td>
							<td align="center">
								380
							</td>
							<td align="center">
								480
							</td>
							<td align="center">
								580
							</td>

						</tr>
						<tr>

							<td align="center">
								<b>Q</b>
							</td>
							<td align="center">
								85
							</td>
							<td align="center">
								185
							</td>
							<td align="center">
								285
							</td>
							<td align="center">
								385
							</td>
							<td align="center">
								485
							</td>
							<td align="center">
								585
							</td>

						</tr>
						<tr>

							<td align="center">
								<b>R</b>
							</td>
							<td align="center">
								90
							</td>
							<td align="center">
								190
							</td>
							<td align="center">
								290
							</td>
							<td align="center">
								390
							</td>
							<td align="center">
								490
							</td>
							<td align="center">
								590
							</td>

						</tr>
						<tr>

							<td align="center">
								<b>S</b>
							</td>
							<td align="center">
								95
							</td>
							<td align="center">
								195
							</td>
							<td align="center">
								295
							</td>
							<td align="center">
								395
							</td>
							<td align="center">
								495
							</td>
							<td align="center">
								595
							</td>

						</tr>
						<tr>

							<td align="center">
								<b>T</b>
							</td>
							<td align="center">
								100
							</td>
							<td align="center">
								200
							</td>
							<td align="center">
								300
							</td>
							<td align="center">
								400
							</td>
							<td align="center">
								500
							</td>
							<td align="center">
								600
							</td>

						</tr>
						<tr>

							<td align="center">
								<b>U</b>
							</td>
							<td align="center">
								7.5
							</td>
							<td align="center">
								37.5
							</td>
							<td align="center">
								67.5
							</td>
							<td align="center">
								97.5
							</td>
							<td align="center">
								127.5
							</td>
							<td align="center">
								157.5
							</td>

						</tr>
						<tr>

							<td align="center">
								<b>V</b>
							</td>
							<td align="center">
								12.5
							</td>
							<td align="center">
								42.5
							</td>
							<td align="center">
								72.5
							</td>
							<td align="center">
								102.5
							</td>
							<td align="center">
								132.5
							</td>
							<td align="center">
								162.5
							</td>

						</tr>
						<tr>

							<td align="center">
								<b>W</b>
							</td>
							<td align="center">
								17.5
							</td>
							<td align="center">
								47.5
							</td>
							<td align="center">
								77.5
							</td>
							<td align="center">
								107.5
							</td>
							<td align="center">
								137.5
							</td>
							<td align="center">
								167.5
							</td>

						</tr>
						<tr>
							<td align="center">
								<b>X</b>
							</td>
							<td align="center">
								22.5
							</td>
							<td align="center">
								52.5
							</td>
							<td align="center">
								82.5
							</td>
							<td align="center">
								112.5
							</td>
							<td align="center">
								142.5
							</td>
							<td align="center">
								172.5
							</td>

						</tr>
						<tr>
							<td align="center">
								<b>Y</b>
							</td>
							<td align="center">
								27.5
							</td>
							<td align="center">
								57.5
							</td>
							<td align="center">
								87.5
							</td>
							<td align="center">
								117.5
							</td>
							<td align="center">
								147.5
							</td>
							<td align="center">
								177.5
							</td>

						</tr>
						<tr>
							<td align="center">
								<b>Z</b>
							</td>
							<td align="center">
								32.5
							</td>
							<td align="center">
								62.5
							</td>
							<td align="center">
								92.5
							</td>
							<td align="center">
								122.5
							</td>
							<td align="center">
								152.5
							</td>
							<td align="center">
								182.5
							</td>

						</tr>
					</table>
				</div>
			</div>
        </>
    )
}