import React from 'react';
import GoogleAdComponent from './ads/GoogleAdComponent';
import AmazonAdComponent from './ads/AmazonAdComponent';
import BullCallSpread_Image from '../images/bull_call_spread.png';
import BearCallSpread_Image from '../images/bear_call_spread.png';
import BullPutSpread_Image from '../images/bull_put_spread.png';
import BearPutSpread_Image from '../images/bear_put_spread.png';


export default function VerticalSpread() {
    return (
        <>
             <div id="left">
				<h1>
					Option Strategies
					<br />
				</h1>
				<h4>Vertical Spread
				</h4>
				Now we are getting into more complicated strategies.  Up until now we've just been
				buying or selling options with a single leg. That is, a transaction with only one
				part to it.  A transaction can have more than one leg.  If all legs of a transaction
				cannot be fulfilled, then the transaction does not go through.   In the case of a
				Vertical Spread, there are four flavours of it:
				<ol>
					<li>Bull Call Spread</li>
					<li>Bear Call Spread</li>
					<li>Bull Put Spread</li>
					<li>Bear Put Spread</li>
				</ol>
				You can guess from the name that the spread will include either Call options or Put
				options and that you should use it as either a Bullish or Bearish strategy.  The term
				<i>Spread</i> refers to the dollar difference between the two legs of that transaction.
				The examples below will show a $1 spread, but other spreads may be available also.
				<h5>1. Bull Call Spread</h5>

				<div class="imageresize"><img border="0" alt="bull call spread" src={BullCallSpread_Image} /></div>
				Looking at the diagram above, you can see that there are two legs to this
				transaction.  Leg 1, you sell a call option and leg 2 you buy a call option.  Now
				imagine that the market price is somewhere in the middle.  A Call
				option where the strike price is still above the market price will have no
				intrinsic value.  A Call option where the market is already trading above
				the strike price will have some intrinsic value.  So one option is worth
				more than the other.
				<br /><br />
				In a Bull Call Spread, you simultaneously sell the higher strike price option
				and buy the lower strike price option.  Say you receive $1.00 for selling it and
				pay $1.20 for buying the other option.  When you net the two you end up paying $0.20
				per option.  That is called a <strong>Net Debit Spread</strong>.  When you enter your order for this
				type of trade, you specify what you want your net debit amount to be.  The brokerage
				firm will buy and sell the options for you as one single transaction.  It will also
				figure out how much to pay for the option and how much to accept for the other option
				as long as the net amount meets your criteria.
				<br /><br />
				So how do you make money with this?  Refer back to the piciture above.  In order
				for you to make any money, the price of the underlying stock will have to be above
				the lower strike price option.  And even then you may not recuperate your entire investment.
				You get the maximum profit if the market price is above both strike prices. Let look at
				some scenarios:
				<h5>Table 1:</h5>
				<div class="block">
					<table width="100%" cellspacing="0" cellpadding="0" border="1" class="example">
						<thead>
							<tr>
								<td colspan="4">
									Stock price at expiration: $9
								</td>
							</tr>
							<tr>
								<td>
									Leg
									<br />
								</td>
								<td>
									Action
									<br />
								</td>
								<td>
									Option Price
									<br />
								</td>
								<td>
									Option Price at Expiration
									<br />
								</td>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>
									1
									<br />
								</td>
								<td>
									Sell
									<br />
								</td>
								<td>
									 $1.00
									<br />
								</td>
								<td>
									 $0.00
									<br />
								</td>
							</tr>
							<tr>
								<td>
									2
									<br />
								</td>
								<td>
									Buy
									<br />
								</td>
								<td>
									 $1.20
									<br />
								</td>
								<td>
									 $0.00
									<br />
								</td>
							</tr>
							<tr>
								<td>
									Net
									<br />
								</td>
								<td>
									&nbsp;
									<br />
								</td>
								<td>
									 $0.20
									<br />
								</td>
								<td>
									 $0.00
									<br />
								</td>
							</tr>
						</tbody>
					</table>
				</div>
				<h5>Table 2:</h5>
				<div class="block">
					<table width="100%" cellspacing="0" cellpadding="0" border="1" class="example">
						<thead>
							<tr>
								<td colspan="4">
									Stock price at expiration: $9.5
								</td>
							</tr>
							<tr>
								<td>
									Leg
									<br />
								</td>
								<td>
									Action
									<br />
								</td>
								<td>
									Option Price
									<br />
								</td>
								<td>
									Option Price at Expiration
									<br />
								</td>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>
									1
									<br />
								</td>
								<td>
									Sell
									<br />
								</td>
								<td>
									 $1.00
									<br />
								</td>
								<td>
									 $0.00
									<br />
								</td>
							</tr>
							<tr>
								<td>
									2
									<br />
								</td>
								<td>
									Buy
									<br />
								</td>
								<td>
									 $1.20
									<br />
								</td>
								<td>
									 $0.50
									<br />
								</td>
							</tr>
							<tr>
								<td>
									Net
									<br />
								</td>
								<td>
									&nbsp;
									<br />
								</td>
								<td>
									 $0.20
									<br />
								</td>
								<td>
									 $0.50
									<br />
								</td>
							</tr>
						</tbody>
					</table>
				</div>

				<h5>Table 3:</h5>
				<div class="block">
					<table width="100%" cellspacing="0" cellpadding="0" border="1" class="example">
						<thead>
							<tr>
								<td colspan="4">
									Stock price at expiration: $9.9
								</td>
							</tr>
							<tr>
								<td>
									Leg
									<br />
								</td>
								<td>
									Action
									<br />
								</td>
								<td>
									Option Price
									<br />
								</td>
								<td>
									Option Price at Expiration
									<br />
								</td>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>
									1
									<br />
								</td>
								<td>
									Sell
									<br />
								</td>
								<td>
									 $1.00
									<br />
								</td>
								<td>
									 $0.00
									<br />
								</td>
							</tr>
							<tr>
								<td>
									2
									<br />
								</td>
								<td>
									Buy
									<br />
								</td>
								<td>
									 $1.20
									<br />
								</td>
								<td>
									 $0.90
									<br />
								</td>
							</tr>
							<tr>
								<td>
									Net
									<br />
								</td>
								<td>
									&nbsp;
									<br />
								</td>
								<td>
									 $0.20
									<br />
								</td>
								<td>
									 $0.90
									<br />
								</td>
							</tr>
						</tbody>
					</table>
				</div>

				<h5>Table 4:</h5>
				<div class="block">
					<table width="100%" cellspacing="0" cellpadding="0" border="1" class="example">
						<thead>
							<tr>
								<td colspan="4">
									Stock price at expiration: $10.1
								</td>
							</tr>
							<tr>
								<td>
									Leg
									<br />
								</td>
								<td>
									Action
									<br />
								</td>
								<td>
									Option Price
									<br />
								</td>
								<td>
									Option Price at Expiration
									<br />
								</td>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>
									1
									<br />
								</td>
								<td>
									Sell
									<br />
								</td>
								<td>
									 $1.00
									<br />
								</td>
								<td>
									 $0.10
									<br />
								</td>
							</tr>
							<tr>
								<td>
									2
									<br />
								</td>
								<td>
									Buy
									<br />
								</td>
								<td>
									 $1.20
									<br />
								</td>
								<td>
									 $1.10
									<br />
								</td>
							</tr>
							<tr>
								<td>
									Net
									<br />
								</td>
								<td>
									&nbsp;
									<br />
								</td>
								<td>
									 $0.20
									<br />
								</td>
								<td>
									 $1.00
									<br />
								</td>
							</tr>
						</tbody>
					</table>
				</div>

				<h5>Table 5:</h5>
				<div class="block">
					<table width="100%" cellspacing="0" cellpadding="0" border="1" class="example">
						<thead>
							<tr>
								<td colspan="4">
									Stock price at expiration: $20
								</td>
							</tr>
							<tr>
								<td>
									Leg
									<br />
								</td>
								<td>
									Action
									<br />
								</td>
								<td>
									Option Price
									<br />
								</td>
								<td>
									Option Price at Expiration
									<br />
								</td>
							</tr>
						</thead>
						<tbody>
							<tr>
								<td>
									1
									<br />
								</td>
								<td>
									Sell
									<br />
								</td>
								<td>
									 $1.00
									<br />
								</td>
								<td>
									 $10.00
									<br />
								</td>
							</tr>
							<tr>
								<td>
									2
									<br />
								</td>
								<td>
									Buy
									<br />
								</td>
								<td>
									 $1.20
									<br />
								</td>
								<td>
									 $11.00
									<br />
								</td>
							</tr>
							<tr>
								<td>
									Net
									<br />
								</td>
								<td>
									&nbsp;
									<br />
								</td>
								<td>
									 $0.20
									<br />
								</td>
								<td>
									 $1.00
									<br />
								</td>
							</tr>
						</tbody>
					</table>
				</div>
				<br />
				The above tables show what your profit would be as the stock price increases upon expiration.  Table
				1 clearly shows that the options are worth nothing if the stock price does not go above
				the lower strike price option (i.e., $9). You invested a total of $0.20/option.  That money
				you will lose entirely.  On the other end of the extreme, you see table 5.  Here the stock price
				is so high, yet your profit is only $1.00/option.  Why is that?  Remember that there are two
				legs to the transaction.  You have to close them both.  In leg 1 you sold an option at strike
				price $10.  Now you have to close that position by buying it back.  At the same in leg 2 you you
				have to sell to close that position.  So the net will always be $1.00.
				<br /><br />
				So the most money you can make is $80 per option ($100 minus $20 initial investment) in our
				example.  That's not too bad considering the low risk.  You are assured that you won't
				lose more than $20 per option no matter how bad the stock performs.
				<br /><br />
				<h5>2. Bear Call Spread</h5>
				<div class="imageresize"><img src={BearCallSpread_Image} alt="Bear Call Spread" /></div><br />
				<AmazonAdComponent type='mid' />
				This strategy is the opposite of the previous one.  In leg 1, you
				buy the Call option and leg 2 you sell the Call option.  So remember
				that when you buy an option far out of the money it is worth less
				than one that is not as far out of the money.  In this case
				leg 1 will be cheaper than leg 2.  If you net the legs, you actually
				end up receiving some money.  Therefore, this is a <strong>Net Credit
				Spread</strong>.  And looking at the picture above, your goal is
				to try and keep your net credit.  The way it happens is if the price
				of the underlying stock goes down.  That's why this is called a "Bear"
				strategy.  Even though you'r dealing in Call options your outlook is bearish.
				<br /> <br />
				The most money you can lose is $100 per option.  Just like with the Bull Call
				Spread you have to understand what happens at expiration.  In leg 1, you
				will end up buying at the $10 strike price and selling at the market price,
				which is much higher.  That's a good thing, but remember in leg 2 you sold
				a Call option which allows someone else to buy the stock from you at the
				low strike price of $9.  So if you match up the two transactions you'll
				end up with a $1/option loss.
				<h5>3. Bull Put Spread</h5>
				<div class="imageresize"><img src={BullPutSpread_Image} alt="bull put spread" /></div><br />
				Now we're moving away from Call and into Put options.  This is a bull
				strategy so you'll make money when the stock price goes up (just as in
				the Bull Call Spread).  The difference is that this is a <strong>
				Net Credit Spread</strong> so your profit is the actual credit you
				receive instead of the max profit of $1/Option.
				<br /><br />
				In leg 1 you sold a Put option so that means somebody can sell you their
				stock at the strike price of $10.  Since this happens when the market price
				is lower than the strike price, you'll end up paying too much for the stock.
				But that's where leg 2 comes into play.  It will limit your losses.  Leg 2
				allows you to sell your shares at $9/share.  You bought that right by buying
				a Put option.  All you do is match the transactions in red font like you see
				in the picture and you can see that the leg 1 buy transaction is matched with
				the leg 2 sell transaction.  You buy at $10 and sell at $9 for a $1/share loss.
				Quick recap:  since each option controls 100 shares, it's actually $100 loss per
				option.
				<GoogleAdComponent googleAdSlotID='8974568762' />
				<h5>4. Bear Put Spread</h5>
				<div class="imageresize"><img src={BearPutSpread_Image} alt="Bear Put Spread" /></div><br />
				This strategy requires you to have a bearish outlook (as the name suggests) in order
				for you to realize any profit.  Using the knowledge you have gained from the
				earlier examples you should deduct that this is a <strong>Net Debit Spread.</strong>
				Take a moment to think about why.
				<br /><br />
				Because both legs are Put options you would pay more for the option at the higher
				strike price because as the market prices goes lower the $10 strike price option
				is worth more.  I.e., it is way in the money.  As in the previous spreads the max
				loss is whatever you invested as your net debit and the max profit is $100/Option.
			</div>
        </>
    )
}