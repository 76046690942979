export const PATHS = {
    HOME : 'Home',
    BASICS : 'Basics',
    STRATEGIES : 'Strategies',
    WEEKLYOPTIONS : 'WeeklyOptions',
    MINIOPTIONS : 'MiniOptions',
    CHEATSHEET : 'CheatSheet',
    BASICEXAMPLES : 'BasicExamples',
    COVEREDCALL : 'CoveredCall',
    PROTECTIVEPUT : 'ProtectivePut',
    SECUREDPUT : 'SecuredPut',
    VERTICALSPREAD : 'VerticalSpread',
    CALENDARSPREAD : 'CalendarSpread',
    DIAGONALSPREAD : 'DiagonalSpread',
    IRONCONDORSPREAD : 'IronCondorSpread',
    STRANGLE : 'Strangle',
    STRADDLE : 'Straddle'
}