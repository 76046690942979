import React from 'react';
import GoogleAdComponent from './ads/GoogleAdComponent';
import AmazonAdComponent from './ads/AmazonAdComponent';
import IBMCall_Image from '../images/IBM_Call.png';
import IBMCall_V2_Image from '../images/IBM_Call_v2.png';



export default function Basics() {
    return (
        <>
        <div id="left">
				<h1>
					Basic Options - What is an option?
					<br />
				</h1>
				<p>
				    In this options tutorial article, we'll discuss the very basics of Option Contracts.  That is,
				    what is an option? how to trade options? and how do you profit from an option?
				    <br /><br />
					Put simply, an option is a contract which you can buy from someone or sell to someone.
                    Your responsibilities depend on whether you are the one buying or selling.
                    <br /><br />
					Now before getting into those responsibilities, lets talk about some important characteristics
					of an option contract and then we'll build slowly on an example.
					<br />
					<h4>Option Characteristics</h4>
					<div class="block">
						<ul>
							<li>An option contract is based on some underlying stock like IBM.</li>
							<li>An option contract will always have an expiration date.</li>
							<li>An option contract will always have what's called a Strike Price.</li>
							<li>An option contract can be one of two types: Call or Put</li>
						</ul>
					</div>
					<br />
					Lets talk about each bullet in more detail.  I mentioned that an option is simply a contract,
					but a contract to do what? It is a contract which gives the buyer the right to trade the underlying
					stock.  One option contract is good for 100 shares of that underlying stock.  So buying an
					IBM option will give you some right to trade 100 physical shares of IBM.

					<br /><br />
					The contract will also enforce a time frame to make that trade.  An option will expire at the
					close of the third Friday of the stated expiration month.  So if you buy an IBM option with an
					May 2009 expiration month, you have until end of trading day May 15, 2009 to trade
					those 100 shares of IBM.

					<br /><br />
					In addition, the contract will specify a strike price.  This is referring to the price
					of the underlying stock (not the option itself).  So to build on our IBM example, you
					could buy an IBM option for May 2009 where the strike price is $105 and that would
					give you the right to trade IBM stock at the Strike Price of $105/share.

					<br />
					<AmazonAdComponent type='mid' />
					<h4>Call vs Put</h4>
					But wait, there is something we're still missing.  You may be asking yourself,
					well so what? I have an IBM option for May 2009 where the strike price is $105 and
					I can trade at that strike price.  But do I buy at $105 or sell at $105.  That's where
					another very important characteristic comes into play and that is Call vs Put.  If you buy
					an IBM Call Option, you are given the right to buy 100 shares of IBM at $105/share.  If you buy
					an IBM Put Option, you are given the right to sell 100 shares of IBM at $105/share.

					<br /><br />
					So which one do you choose? Call or Put?  That depends on your personal belief on how IBM
					stock will behave.  Remember that an option contract has an expiration date.  In our example,
					it is May 15, 2009.  So you have to ask yourself,
					<div class="block">"do I think IBM will be above or below
					$105/share on May 15, 2009?" </div><br />
					Since the expiration date is in the future you cannot
					say with any certainty, but you could make an educated guess.  If you think IBM will be
					above $105/share, you want to buy a Call Option.  Why? Because a Call Option will give
					you the right to buy 100 shares of IBM at $105/share.  Now imagine IBM does really well
					and on May 15, it is trading at $110/share.  If you exercise your right, you will
					buy 100 shares at $105 and sell 100 shares at $110.  You've just made a $500 profit.
					The difference between $110 and $105 multiplied by 100 shares.
					<br /><br />
					Okay, but what if you think IBM will be below $105/share on May 15, 2009? Well then you
					want to buy a Put Option.  Why? Because a Put Option will give you the right to sell
					100 shares of IBM at $105/share.  Suppose that IBM is trading at $100/share on May 15.
					Well, if you exercise your right under a Put Option you will buy 100 shares of IBM at $100
					and sell 100 shares at $105.  Do the math and you've made a $500 profit.
					<br /><br />
					<div class="block">
					To summarize, a Call Option gives you the right to buy low while a Put Option gives you the right
					to sell high.
					</div>
					<br />
					Remember that buying the option contract gives you that right.  Which means the person
					selling you the contract is actually giving you that right.  When you exercise the Call Option
					you are actually buying those 100 shares from that person at the strike price of $105 and
					selling those same 100 shares on the market at $110.  If it were a Put Option,  you are
					buying 100 shares from the market at $100 and selling those 100 shares to that person at the
					strike price of $105. In both scenarios you are buying low and selling high!
					<br /><br />
					Now when I say you are buying and selling shares, it's not exactly correct. Your brokerage
					firm is doing the buying/selling of the shares and giving you the net profit in your account.
					That's called an Option Assignment. And your brokerage firm will charge you a small fee for
					handling the nitty gritty transactions in the back end.
					<br />
					<GoogleAdComponent googleAdSlotID='9563874105' />
					<h4>Option Premium</h4>
					The one thing we didn't talk about so far is how much does it cost to buy an option contract?
					That depends on two factors.  How close the current market price is to the strike price and
					how much time is left before the option expires.  These two concepts are called <strong>Intrinsic
					Value</strong> and <strong>Time Value</strong>.  A Call Option is said to have intrinsic value if the
					current market price is above the strike price.  Generally the price of the option increases by $100
					for each $1 increase in the price of the underlying stock above the strike price.  The rest of the
					option price is the Time Value.
					<br />
					<div class="block">
						A quick side note about how option premiums are stated.  When you see an option price quote, you will
						typically see the price divided by 100.  So if the option will cost you $430, it will be stated as
						$4.30.  It's stated that way because one option controls 100 shares.
						Don't be confused or mislead and buy more options than you can handle!
					</div>
					<br />
					For example, say the price of the IBM $100 May contract is $4.30.
					If the current date is April 20 you still have 4 weeks until expiration plus the current market price
					is $102.31.  That means approximately $2.31 of the option is intrinsic while $1.99 is the time value.
					For a Put Option, obviously the Intrinsic Value would be based on how much lower the market price
					is relative to the strike price.
					<br />
					<h4>Time Decay</h4>
					An important factor to consider is the decay of time.  Suppose the price of the IBM stock in our
					previous example remained at $102.31, but it got closer and closer to May 15.  As you approach the expiration
					date, the Time Value decreases and on May 15, the option will be worth $2.31.  I.e., the Time Value is now $0.
					The Intrinsic Value doesn't decay, just the Time Value.  Remember that you bought a May $100
					option which means you have the right to buy at $100 and sell at $102.31.
					<br />
					<h4>Buying and Selling Options</h4>
					All this discussion was assuming the fact that you would keep the option contract
					until expiration.  But the fact is you may not want to.  In reality many people do
					not buy and hold the option that long.  If they see an increase in the option they bought
					they will most likely sell the option and take their profit.
					<br /><br />
					Earlier I mentioned that the cost of the option
					was dependent on Intrinsic Value and Time Value.  Now you know that as time proceeds
					the decay in Time Value will decrease the value of your option.  So the only way to make
					money is to hope that the underlying stock moves in your favour. Going back to our old example
					of the IBM May $105 Call option, the option premium (that is, the money you paid to acquire
					the option) will decrease as you get closer to May 15.  But if IBM's market price increases as well,
					the decay in time value may be offset.  How?
					<br /><br />
					Think of it like this; what are the chances that IBM will
					trade at $105 or better if you have 2 weeks to go and the price is already at $104?  Now ask yourself
					what are the chances if the price is currently $90.  You can probably guess by now that the closer
					the market price is to the strike price, the more the option is worth.  So if you bought the option
					for $4.30 when IBM was trading at $102.31 and the next day it went to $104, that option will be worth
					more (lets say $5.50).  Now you can wait and see what happens on May 15th, but if you just wanted to take advantage of
					a short term price swing you can take your profits right now and run.  In our example, you made a profit
					of $120. That is ($5.50 - $4.30) * 100.
					<br />
					<h4>How to Read Option Chains</h4>
					
					<strong>NOTE:  This section about reading options chains has been out dated, but it is still worthwhile to read through because you may still
					encounter these in various other websites.  Click <a href="#newchain">here</a> to find out the latest method of reading options chains.</strong>
					<br /><br />
					Now that you know so much about options, lets talk about how to find them and how to interpret what
					you see.  Lets go with our working example of IBM Call Options.  You can look at the diagram below
					or go directly to Yahoo by opening another browser page and entering the URL
					<a href="http://finance.yahoo.com/q/op?s=IBM" target="_blank" rel="noopener noreferrer" >
					http://finance.yahoo.com/q/op?s=IBM</a>.  As you can see there is a table
					like the one below:
					<br />
					<img src={IBMCall_Image} alt="IBM Call Option Chain" />
					<br />
					Our famous IBM $105 Call Option is listed here as you can tell by the color coded circles.
					The red circle indicates this is for May 2009.  The first column shows all the available
					strike prices.  The green circle shows a weird looking symbol.  It's certainly not the symbol
					for IBM, but it looks similar.  There is a standard for listing option quotes which you can
					see by going to the cheat sheet (see link on the right hand navigation).
					<br /><br />
					You can probably figure out the rest of the circles if you've seen stock quotes.  A couple of
					things to point out is the pricing standard and the highted area.  Note that it does not list the option
					as costing $195 or that the price has increased by $10.  It is divided by 100 and then listed.
					The volume however, has not been divided by anything!  It really is 64.   The final thing to note
					is the area highlighted in yellow.  Remember we talked about Intrinsic Value? Well all the options
					that have Intrinsic Value are highlighted in yellow because the current IBM stock price is $102.55.
					<div class="block">
						The yellow highlighted options are referred to as "In the money" options.
					</div>
					<h4>Buyer Beware</h4>
					Until now I've just been giving pure facts about options.  Now I'm going to give some advice.
					You have to be very careful when trading options.  People often tout the upside to options
					investing while playing down the risks involved.  If you watch T.V. you'll notice lots of advertisements
					enticing you to sign up so you can "realize tremendous profits...using leverage".  While it is
					true that you can realize tremendous profits, the chances of you realizing tremendous losses are
					just as great..if not more.  Even the best and brightest investment professionals cannot predict
					price movement especially over the short term.  They get it wrong just as often as they get it right.
					At the end of the day, options are meant to add another dimension to your entire investment
					strategy, so be careful not to get wiped out as soon as you enter the option world.   It's best
					to start out playing 5 to 10 options at a time.  If you find you've made some money doing it, then
					you can risk more capital.
					<h4>New Options Chains</h4>
					As mentioned above, there is a new way to read options chains and it is quite easy to understand.  You will see below:
					<br />
					<img src={IBMCall_V2_Image} alt="New IBM Call Option Chain" />
					<br />
					You can see it is almost self explanatory.  The red circle represents the underlying stock symbol, the blue circle represents the 
					expiration date, the green circle represents the type of option ("C" for Call, "P" for Put), and the black circle represents the
					the strike price.   The one small catch is that the expiration date is stated as the day after the actual expiration date.  I know weird, but
					the option actually expires as of close of market the day before. In the example above, the expiration date of '130322' reads 2013, March 22nd.  
					That is, the option is already expired as of that morning.  But for your trading purposes you have to make sure that whatever trade you want to make has to get 
					in before the close of market on 2013, March 21st.  
					<h4>Summary</h4>
					Okay, we've gone through a lot of material here.  And you might still be confused.  I suggest you
					read the material again or at least the parts where you got lost.  Also from the menu above you
					can refer to a cheat sheet which lists all the important things you need to know about options
					without the long boring explanations and examples.
					<br /><br />
					Good Luck!  And don't forget to come back soon for any updates or new material I add to this site.
				</p>
				</div>



        </>
    )
}